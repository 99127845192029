
<script setup>
import { computed, onMounted } from 'vue';
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
  id: {
    type: String,
    default: () => null
  },
  value: {
    type: [String, Number, Boolean, Object],
    default: null
  },
  modelValue: {
    type: [Boolean, Array],
    default: null
  },
});

const emit = defineEmits(['update:modelValue',"change"]);
const checkboxRef=ref(null)
const isChecked = ref(false)
const toggle = computed({
    get() {
      return props.modelValue;
    },
    set(value) {
      emit("update:modelValue", value);
    }
  });

const onChange = (event) => {
   isChecked.value=checkboxRef.value.checked
    emit("change", event.target.checked);
  };

  onMounted(()=>{
    isChecked.value=props.modelValue.some(item=>item.key==props.value.key)
  })
  
 
</script>
<template>
    <div class="flex items-center space-x-2 cursor-pointer" >
        <span class="relative flex items-center justify-center" >
                <input               
                    type="checkbox"
                    v-model="toggle"
                    @change="onChange"
                    :value="value"
                    ref="checkboxRef"
                    :class="[`appearance-none border rounded w-5 h-5 ${isChecked? 'checked:bg-indigo-800 checked:ring-indigo-800':''} cursor-pointer`]"
                />
                <i v-if="isChecked" @click="checkboxRef.click()"  class="fas fa-check text-white  text-xs absolute top-1"></i>
        </span>
     
      <label  @click="checkboxRef.click()">
        <slot name="label"></slot>
      </label>
    </div>
</template>
